const navData = [
  {
    id: 1,
    data: "About",
  },
  {
    id: 2,
    data: "Resume",
  },
  {
    id: 3,
    data: "Projects",
  },
  {
    id: 4,
    data: "Contact Me",
  },
];

export default navData;
